if (window.acima == undefined) {
  window.acima = {};
}
window.acima.fetchInit = function(newOptions) {
  var extendedOptions = {};
  var options = {
    credentials: newOptions.credentials || "same-origin",
    headers: new Headers(
        {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token":
            document.head.querySelector("meta[name=csrf-token]").content,
          "X-Fetch-Request": true,
        }
    ),
  };
  extendedOptions = Object.assign({}, newOptions, options);
  return extendedOptions;
};
